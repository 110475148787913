import styled from 'styled-components'
import { Link } from 'gatsby'
import { colors, md } from '../common'

export const Wrap = styled.div`
  background-color: ${colors.green};
  color:white;
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 2rem;
  text-transform: uppercase;

  a {
    color: white;
  }

  ${md} {
    flex-direction: column;
    padding:1rem;
  }
`

export const A = styled(Link)`
  height:100%;
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
`

export const Card = styled.div`
  padding: 0 2rem;
  width:20%;

  ${md} {
    width:100%;
    padding:0;
    border:0;
  }
`
