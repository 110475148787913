import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import GreenBar from '../sections/greenBar4'
import { PageHead, colors, md } from '../common'
import History from '../sections/history'

export default () => (
  <Layout>
    <SEO
      title="Our History"
      description="Underwood’s was founded by Herb F. Underwood in Palatka, FL in 1928. He moved Underwood’s to Jacksonville, FL in 1940 and the Bromberg family purchased the business in 1974." />
    <PageHead>
      <h1>OUR HISTORY</h1>
      <p>Underwood Jewelers was founded in 1928, by industry legend Herbert F. Underwood. Underwood’s started as a diamond and watch store in Palatka, Florida, just prior to The Great Depression. However, because of great skills in buying and selling diamonds and a lot of hard work Underwood expanded to Jacksonville, Florida, in 1940; even though the Depression was still going on. At the start of World War II, Mr. Underwood sold the Palatka store and consolidated in Jacksonville.</p>
      <img css={`width:100%;max-width:500px;`} src="https://s3.amazonaws.com/underwoods/Underwood's+Old.jpg" alt="original storefront" />
    </PageHead>
    <GreenBar />
    <div css={`display:flex;padding:2rem;${md}{flex-direction:column;}`}>
      <div css={`width:40%;padding:2rem;margin:auto;${md}{width:100%;padding:0;}${md}{width:100%;padding:0;}`}>
        <iframe title={'youtube video embed'} css={`border:0;${md}{width:100%;}`} width="560" height="315" src="https://www.youtube.com/embed/_wxRRdLCYh8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div css={`width:50%;padding:2rem;text-align:center;margin:auto;line-height:20px;color:${colors.grey};${md}{width:100%;padding:0;}`}>
        <p>Mr. Underwood continued to grow the downtown business each year but also kept a keen eye on other potential locations around Jacksonville. In 1953, in an unprecedented move for a downtown retailer, Underwood’s opened its first branch store in the San Marco neighborhood of Jacksonville. Underwood’s continued its growth into four more stores through the 60’s and 70’s using television advertising as a means to educate customers and build their trust. While this was happening, Herb Underwood served the jewelry industry as President of Jewelers of America, and served on other national boards like the American Gem Society, Jewelers Vigilance Committee, The Florida Jewelers Association, and the Florida Retail Federation.</p>
        <p>In 1974, Mr. Underwood decided to sell his business to one of the oldest and most respected family jewelry businesses in the country: Bromberg and Company. The Bromberg family jewelry business was founded in Alabama in 1836, and is the oldest business in that state. They also ranked as the second oldest continuously running family retail establishment in the United States. Frank Bromberg, Herb Underwood’s long-time friend, urged him to stay active in the day to day operations, which he did until 1980. He then worked with Clayton Bromberg in a training and supervisory role until the younger Bromberg was made the President of the firm in 1988. In 1989, Mr. Underwood was recognized for his contributions to the jewelry industry when the American Gem Society honored him with the Shipley Award. Herbert Underwood passed away on July 4, 1998.</p>
      </div>
    </div>
    <History />
    <JoinUs />
  </Layout>
)
