import React from 'react'
import { A, Card, Wrap } from '../common/greenBar'

export default () => (
  <Wrap>
    <Card>
      <A to="/our-history">
        <h2>Our History</h2>
        <div>
          <img src="https://s3.amazonaws.com/underwoods/history.png" alt="history icon" />
        </div>
        <p>See Our History</p>
      </A>
    </Card>

    <Card css={`border-left:1px solid white;border-right:1px solid white;`}>
      <A to="/awards">
        <h2>Awards</h2>
        <div>
          <img src="https://s3.amazonaws.com/underwoods/awards.png" alt="awards icon" />
        </div>
        <p>See our awards</p>
      </A>
    </Card>

    <Card css={`border-right:1px solid white;`}>
      <A to="/our-diamond">
        <h2>our Diamond</h2>
        <div>
          <img src="https://s3.amazonaws.com/underwoods/icon_our-diamond.png" alt="our diamond icon" />
        </div>
        <p>View our diamond</p>
      </A>
    </Card>

    <Card>
      <A to="/our-team">
        <h2>Our Team</h2>
        <div>
          <img src="https://s3.amazonaws.com/underwoods/experience.png" alt="team icon" />
        </div>
        <p>View our team</p>
      </A>
    </Card>

  </Wrap>
)
