import React from 'react'
import styled from 'styled-components'
import { md } from '../common'

export default () => (
  <Wrap>
    <Copy>
      <p>Clayton Bromberg, like Herb Underwood before him, serves and has served on jewelry industry boards such as Jewelers of America, American Gem Society, Jewelers Vigilance Committee, Florida Jewelers Association, The Better Business Bureau, and served as President of the American Gem Society from 1995 until 1997. He also has served on the American Gem Society Laboratory Board since 1996 and served as its chairman from 2005 to 2008 as well as from 2016 until present. In 2001, under Clayton Bromberg’s leadership, Underwood’s was designated as the first charter member of Leading Jewelers of the World. In 2003, Underwood’s and Clayton Bromberg were recognized for their dedication to the jewelry industry at large and inducted into the National Jewelers Hall of Fame. In 2004, Clayton Bromberg was the recipient of the single most prestigious award in the jewelry industry – The Robert M. Shipley Award. This award is conferred annually to an individual for outstanding service to the American Gem Society, significant contributions to the science of gemology, and for exemplifying the high purpose, objective, and ideals of AGS in the recipient’s community.</p>
      <p>Underwood’s is a cutting edge fine jeweler that is well known and respected in the national and international jewelry industry for our quality operation. We are annually named by the readers of Folio Weekly and Jacksonville Magazine as Northeast Florida’s Finest Jeweler. This has been accomplished by selling high quality diamonds, jewelry, watches, silver, china and giftware.</p>
      <p>Underwood’s operates three distinctive stores in the Jacksonville and Ponte Vedra Beach area, all well stocked with merchandise and staffed by the largest AGS credentialed professional staff east of the Mississippi River.</p>
    </Copy>
  </Wrap>
)

const Wrap = styled.div`
  background: url("https://s3.amazonaws.com/underwoods/History/history-bg.jpg");
  background-size: cover;
  background-position: center top;
  padding: 4rem;
  color: white;
  text-align: center;

  ${md} {
    padding: 1rem;
  }
`

const Copy = styled.div`
  width:60%;
  margin-right:auto;
  padding: 1rem;
  line-height:24px;

  h1 {
    line-height:32px;
  }

  ${md} {
    padding:0;
    margin:0;
    width:100%;
  }
`
